<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2022-10-25 10:33:37
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div class="sld_order_list">
        <MemberTitle :memberTitle="'采购计划审批'"></MemberTitle>
        <div class="container">
            <h3>采购计划审批</h3>

            <div class="sld_order_nav">
                <div class="sld_order_nav_con flex_row_start_center pointer">
                    <div :class="{ item: true, active: param.approveClassify == 0 }" @click="changeState(0)">
                        待审批
                    </div>
                    <div :class="{ item: true, active: param.approveClassify == 1 }" @click="changeState(1)">
                        已审批
                    </div>
                    <!-- <div :class="{item:true,active:current_state==10}" @click="changeState(10)">{{L['待付款']}}</div>
          <div :class="{item:true,active:current_state==20}" @click="changeState(20)">{{L['待发货']}}</div>
          <div :class="{item:true,active:current_state==30}" @click="changeState(30)">{{L['待收货']}}</div>
          <div :class="{item:true,active:current_state==40&&evaluate_state!=1}" @click="changeState(40)">{{L['已完成']}}
          </div>
          <div :class="{item:true,active:current_state==40&&evaluate_state==1}" @click="changeState(40,1)">{{L['待评价']}}
          </div> -->
                </div>

                <div class="ApprovalSearch">
                    <el-form ref="ruleFormRef" :model="param" :inline="true" class="demo-form-inline">
                        <el-form-item label="采购单号" prop="memberPlanCode">
                            <el-input v-model="param.memberPlanCode" style="width: 195px" />
                        </el-form-item>
                        <el-form-item label="创建人" prop="prayName">
                            <el-input v-model="param.prayName" style="width: 195px" />
                        </el-form-item>
                        <el-form-item label="部门" prop="deptName">
                            <el-input v-model="param.deptName" style="width: 195px" />
                        </el-form-item>
                        <el-form-item label="商品名称" prop="goodsName">
                            <el-input v-model="param.goodsName" style="width: 195px" />
                        </el-form-item>
                        <el-form-item label="月度" prop="startCreateTime">
                            <el-date-picker
                                v-model="param.startCreateTime"
                                type="month"
                                placeholder="请选择月度"
                                value-format="YYYY-MM"
                            />
                        </el-form-item>
                        <div class="searchBtn">
                            <div class="button" @click="getApprovalList()">搜索</div>
                            <el-button @click="resetForm()">重置</el-button>
                        </div>
                    </el-form>
                </div>
                <div class="sld_List" v-if="tableData.data.length !== 0">
                    <div class="order_item" v-for="(orderItem, index) in tableData.data" :key="index">
                        <div class="title flex_row_between_center" style="font-size: 12px">
                            <div class="titleWrap">
                                <div class="order_num1">
                                    <span class="titleColor">采购单号:</span>
                                    <b>{{ orderItem.memberPlanCode }}</b>
                                </div>
                                <div class="order_num1">
                                    <span class="titleColor">到货日期:</span>
                                    <b>{{ orderItem.needDate }}</b>
                                </div>
                                <div class="order_num1">
                                    <span class="titleColor">创建人:</span>
                                    <b>{{ orderItem.prayName }}</b>
                                </div>
                                <div class="order_num1">
                                    <span class="titleColor">部门:</span>
                                    <b>{{ orderItem.deptName || "--" }}</b>
                                </div>
                                <div class="orderPayTypeName">
                                    <p v-if="orderItem.type == 1">月度采购</p>
                                    <p v-else>单次采购</p>
                                </div>
                                <div class="order_num1">
                                    <span class="titleColor">创建时间:</span>
                                    <b>{{ orderItem.createTime }}</b>
                                </div>
                            </div>
                            <router-link
                                target="_blank"
                                :to="{
                                    path: '/member/procurement/procurementPlanApprovalDetail',
                                    query: {
                                        memberPlanCode: orderItem.memberPlanCode,
                                        taskId: orderItem.taskId,
                                        goodsNum: orderItem.goodsNum,
                                        pageNum: param.pageNum,
                                        pageSize: param.pageSize
                                    }
                                }"
                            >
                                <div class="toDetal">查看计划</div>
                            </router-link>
                        </div>
                    </div>
                    <el-pagination
                        style="margin-top: 10px"
                        :vi-if="param.total != 0"
                        v-model:current-page="param.pageNum"
                        v-model:page-size="param.pageSize"
                        :page-sizes="[10, 20, 30, 40]"
                        :small="small"
                        :disabled="disabled"
                        :background="background"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="param.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
                <div v-else>
                    <el-empty description="暂无数据" />
                </div>
            </div>
        </div>
        <!-- 审批选择弹窗 end-->
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted, computed } from "vue";
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
//import { platform } from "@/utils/config";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const current_state = ref(0); //当前列表订单状态
        const evaluate_state = ref(0);
        const ApprovalList = reactive({ data: [] });
        const tableData = reactive({ data: [] });
        const installmentList = reactive({
            data: [
                { id: 0, name: "分期付款全部订单" },
                { id: 1, name: "分期付款未完成订单" },
                { id: 2, name: "分期付款已完成订单" }
            ]
        });
        const param = ref({
            memberId: store.state.memberInfo.memberId,
            memberPlanCode: "",
            prayName: "",
            approveClassify: 0,
            startCreateTime: "",
            deptName: "",
            goodsName: "",

            pageNum: 1,
            pageSize: 10,
            total: 0
        });

        const memberInfo = reactive({ data: store.state.memberInfo });
        //const pageData = reactive({
        //  current: 1,
        //  pageSize: 10,
        //  total: 0
        //});

        //// 使用 computed 创建计算属性
        //const formatOrderState = computed(() => {
        //  return (value) => {
        //    switch (value) {
        //      case 0:
        //        return '已取消'
        //      case 1:
        //        return '待审批'
        //      case 10:
        //        return '待支付'
        //      case 20:
        //        return '待发货'
        //      case 30:
        //        return '待收货'
        //      case 40:
        //        return '已完成'
        //      case 50:
        //        return '已关闭'
        //      default:
        //        return '未知'
        //    }
        //  }
        //})

        // const onSubmit = async (formEl) => {
        //    getApprovalList()
        //      //if (!formEl) return;
        //      //await formEl.validate((valid, fields) => {
        //      //  if (valid) {
        //      //    param.startDate = formEl.model.time[0];
        //      //    param.endDate = formEl.model.time[1];
        //      //    const paramss = { ...param };

        //      //    paramss.time = "";

        //      //    param.time = formEl.model.time;
        //      //    if (paramss.source && paramss.source == 1) {
        //      //      paramss.source = platform;
        //      //    }
        //      //    getOrderList(paramss);
        //      //  } else {
        //      //    console.log("error submit!", fields);
        //      //  }
        //      //});
        //    };
        const resetForm = () => {
            param.value = {
                memberId: store.state.memberInfo.memberId,
                memberPlanCode: "",
                prayName: "",
                approveClassify: param.value.approveClassify,
                startCreateTime: "",
                deptName: "",
                goodsName: "",

                pageNum: 1,
                pageSize: 10,
                total: 0
            };
            getApprovalList();
        };
        //获取审批列表
        const getApprovalList = () => {
            proxy.$get("api/shopp/member/plan/audit/history/page", param.value).then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    console.log(res.data.records);
                    tableData.data = res.data.records;
                    if (res.data.pageSize != 0) {
                        param.value.pageSize = res.data.pageSize;
                        param.value.pageNum = res.data.pageNum;
                        param.value.total = res.data.total;
                        console.log(param.total);
                    } else {
                        param.value.pageSize = 10;
                        param.value.pageNum = 1;
                        param.value.total = 0;
                    }
                }
            });
        };
        //tab切换
        const changeState = (orderState, evaluateState) => {
            console.log(param.value.approveClassify, evaluateState);

            param.value.approveClassify = orderState;
            param.pageNum = 1;

            getApprovalList();
        };
        const handleSizeChange = (val) => {
            param.pageSize = val;
            getApprovalList();
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            param.pageNum = val;
            getApprovalList();
            console.log(`current page: ${val}`);
        };
        onMounted(() => {
            getApprovalList();
        });
        //router.beforeEach((to, from, next) => {
        //  //if (to.query.orderState) {
        //  //  current_state.value = to.query.orderState;
        //  //} else {
        //  //  current_state.value = "";
        //  //}
        //  //if (to.query.evaluateState) {
        //  //  evaluate_state.value = to.query.evaluateState;
        //  //} else {
        //  //  evaluate_state.value = "";
        //  //}
        //  //if (to.path == '/member/order/approvalList') {
        //  //getApprovalList();
        //  //}
        //  next();
        //});
        return {
            current_state,
            changeState,
            //formatOrderState,
            evaluate_state,
            param,
            getApprovalList,
            ApprovalList,
            tableData,
            handleSizeChange,
            handleCurrentChange,
            //onSubmit
            resetForm
        };
    }
};
</script>
<style lang="scss">
@import "../../../style/procurementPlanApprovalList.scss";
</style>
